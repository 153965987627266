export default function FooterLoader() {
  return (
    <footer className="animate-pulse border-t p-4">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="w-full divide-y-2 md:w-1/2 lg:w-1/4 md:pr-4 lg:pr-0">
          <div className="h-10 bg-gray-200 rounded"></div>
          <div className="mt-4">
            <div className="h-4 mb-3 bg-gray-200 rounded"></div>
            <div className="h-4 mb-3 bg-gray-200 rounded"></div>
            <div className="w-1/3 h-3 bg-gray-200 rounded"></div>
          </div>
        </div>
        <nav className="w-full pt-4 md:pt-0 md:w-1/2 lg:w-2/4">
          <ul className="grid text-left md:text-right grid-cols-2 sm:grid-cols-3 gap-4">
            <li>
              <div className="h-4 bg-gray-200 rounded"></div>
            </li>
            <li>
              <div className="h-4 bg-gray-200 rounded"></div>
            </li>
            <li>
              <div className="h-4 bg-gray-200 rounded"></div>
            </li>
            <li>
              <div className="h-4 bg-gray-200 rounded"></div>
            </li>
            <li>
              <div className="h-4 bg-gray-200 rounded"></div>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}
