import PropTypes from "prop-types";
import classNames from "classnames";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import classes from "./Select2.style.scss";

function Select2({ color, outline = true, controlStyles, ...restProps }) {
  const themeColor = null;
  //const themeColor = color === "primary" ? "var(--secondary)" : "var(--primary)";

  const carrotStyle = !outline ? { color: themeColor } : { color: themeColor };
  const IndicatorsContainer = ({ innerRef, innerProps }) => (
    <span {...innerProps}>
      <i style={carrotStyle} className="fas fa-caret-down"></i>
    </span>
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#000",
      //borderBottom: state.isSelected ? 'none !important': 'initial',
      backgroundColor: state.isSelected ? "var(--primary2)" : "transparent",
      cursor: !state.isSelected ? "pointer" : "initial",
      textAlign: "left",
      ": hover": {
        color: !state.isSelected ? "var(--primary2)" : "white",
        transition: "all ease 0.5s",
      },
      ": focus": {
        outline: "none",
      },
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      //minWidth: '150px',
      fontSize: "14px",
      minHeight: "36px",
      outline: "none",
      boxShadow: "none",
      border: state.isFocused
        ? "1px solid " + "var(--primary2)"
        : outline
        ? null
        : "1px solid transparent",
      borderRadius: "2px",
      padding: "0 0.75rem",
      color: "#1F2327",
      "> div": {
        overflow: state.isFocused ? "inherit" : "hidden",
        padding: 0,
      },
      ": hover": {
        borderColor: !state.isSelected ? "var(--primary2)" : "transparent",
      },
      ...controlStyles,
    }),
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: "#9ba3af",
        fontSize: "16px",
      };
    },
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided, state) => {
      return {
        ...provided,
        padding: "0",
        margin: "0",
        borderRadius: "0",
        boxShadow:
          "0 10px 20px rgba(0, 0, 0, 0), 0 6px 6px rgba(0, 0, 0, 0.11)",
        zIndex: 4,
      };
    },
    menuList: (provided, state) => {
      return {
        ...provided,
        padding: "0",
        div: {
          //borderBottom: '1px solid #E8E8EC',
          fontSize: "14px",
          //color: classes.primary,
        },
        "div:last-child": {
          borderBottom: "none",
        },
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        color: themeColor,
        fontSize: "14px",
        lineHeight: "28px",
        opacity,
        transition,
      };
    },
  };

  if (!outline) {
    customStyles.control = (provided, state) => ({
      ...provided,
      width: "100%",
      //minWidth: '150px',
      border: "none",
      outline: "none",
      boxShadow: "none",
      ":focus": {
        border: "none",
      },
      ...controlStyles,
    });

    customStyles.singleValue = (provided, state) => {
      return {
        ...provided,
        color: themeColor,
        borderBottom: "2px solid " + themeColor,
      };
    };

    customStyles.placeholder = (provided, state) => {
      return {
        ...provided,
        color: themeColor,
        borderBottom: "2px solid " + themeColor,
      };
    };

    customStyles.indicatorsContainer = (provided, state) => {
      return {
        ...provided,
        color: themeColor,
      };
    };
  }

  return (
    <ReactSelect
      components={{ ...makeAnimated, IndicatorsContainer }}
      styles={customStyles}
      isClearable={false}
      isSearchable={true}
      {...restProps}
    />
  );
}

export default Select2;
