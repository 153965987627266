import { useEffect, useState } from "react";
import { Select2 } from "components/Form";
import countries from "data/pep-countries.json";
import "./Country.style.scss";

const sortedCountries = countries.sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
});

export default function Country({ value = null, onChange, ...restProps }) {
  const [selected, setSelected] = useState({});
  useEffect(() => {
    if (value) {
      setSelected({ value: value, label: value });
    } else {
      setSelected(null);
    }
  }, [value]);

  const handleChange = (obj) => {
    //setSelected({ value: selectedValue, label: selectedValue });

    const { isMulti = false } = restProps;
    if (isMulti) {
      let values = obj.map((objItem) => {
        return objItem.value;
      });
      onChange(values);
      setSelected(obj);
    } else {
      let { value: selectedValue } = obj;
      onChange(selectedValue);
      setSelected({ value: selectedValue, label: selectedValue });
    }
  };

  return (
    <Select2
      value={selected}
      options={sortedCountries}
      onChange={handleChange}
      {...restProps}
    />
  );
}
