import { Popover } from "components";
import "./RefPopover.style.scss";

const RefPopover = ({ number, content }) => {
  const isLink = content.startsWith("http");
  let finalContent = isLink ? (
    <a href={content}>{content}</a>
  ) : (
    <p>{content}</p>
  );
  return (
    <Popover
      title={
        <a href="#">
          <sup>[{number}]</sup>
        </a>
      }
      content={finalContent}
    />
  );
};

export default RefPopover;
