import Page from "components/Page/Page.Loader";

export default function Home() {
  return (
    <Page>
      <main className="animate-pulse w-full h-screen-with-header bg-cover bg-no-repeat bg-center flex items-center justify-center">
        <div className="w-1/2">
          <div className="bg-gray-200 h-12 w-64 rounded mx-auto"></div>
          <div className="flex items-center justify-center my-4 space-x-2">
            <div className="w-2/3 h-8 bg-gray-200 rounded"></div>
            <div className="w-24 h-8 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-2">
            <div className="w-full h-4 bg-gray-200 rounded"></div>
            <div className="w-full h-4 bg-gray-200 rounded"></div>
            <div className="w-1/3 h-4 bg-gray-200 rounded"></div>
          </div>

          <br />
          <br />
          <div className="space-y-2">
            <div className="w-full h-4 bg-gray-200 rounded"></div>
            <div className="w-full h-4 bg-gray-200 rounded"></div>
            <div className="w-1/3 h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
      </main>
    </Page>
  );
}
