import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import ReCAPTCHA from "react-google-recaptcha";
import APIs from "services/APIs";
import { success, error } from "helpers/Tostify";
import { Input, Button } from "components/Form";
import "./Subscribe.style.scss";

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      reCaptchaToken: null,
      isLoading: false,
    };
    this.validator = new SimpleReactValidator();
    this.recaptcha = React.createRef();
  }

  verifyCallback = (reCaptchaToken) => {
    this.setState({
      reCaptchaToken,
    });
  };

  handleChange = (e) => {
    const email = e.target.value;
    this.setState({
      email,
    });
  };

  subscribe = () => {
    if (!this.validator.fieldValid("email")) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    const { email, reCaptchaToken } = this.state;
    this.setState({
      isLoading: true,
    });
    APIs.addSubscribe({ email, reCaptchaToken })
      .then(({ status, message }) => {
        if (status === "success") {
          this.validator.hideMessages();
          success("Added to subscribers list");
          this.setState({
            email: "",
          });
        } else {
          error(message);
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        error(err.message);
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { className = "" } = this.props;
    const { email, isLoading } = this.state;
    const { REACT_APP_RECAPTCHA_KEY_V2 } = process.env;

    return (
      <>
        <div
          className={classnames("subscriber-form w-full sm:w-2/3", className)}
        >
          <Input
            className="py-2"
            aria-label="Enter Email"
            value={email}
            onChange={this.handleChange}
            placeholder="Enter email"
          />
          <div className="mt-6 block sm:flex items-center">
            <ReCAPTCHA
              ref={this.recaptcha}
              sitekey={REACT_APP_RECAPTCHA_KEY_V2}
              onChange={this.verifyCallback}
            />
            <Button
              disabled={isLoading}
              onClick={this.subscribe}
              className="btn-primary ml-auto mt-6 sm:mt-0"
            >
              {isLoading ? "Sending..." : "Subscribe"}
            </Button>
          </div>
        </div>
        {this.validator.message("email", email, "required|email", {
          className: "text-red-700",
        })}
      </>
    );
  }
}

Subscribe.propTypes = {
  className: PropTypes.string,
};

Subscribe.defaultProps = {
  className: "",
};

export default Subscribe;
