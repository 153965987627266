import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "components/Form";
import { Modal } from "components";

function GoToPage({ visible = false, currentPage = 1, onClose, onPageChange }) {
  const [page, setPage] = useState(currentPage);

  const handleChange = (e) => {
    const regex = /^\d+$/;
    const value = e.target.value;

    if (regex.test(value)) {
      setPage(value);
    }
  };

  const handleSubmit = () => {
    onPageChange(page);
    onClose();
  };

  return (
    <Modal visible={visible} dialogClass="w-10/12 sm:w-1/2 h-60 mt-20">
      <div className="content p-12">
        <h2 className="text-center text-xl">Go to page</h2>
        <div className="mt-4 flex items-center justify-center">
          Page number
          <div className="w-1/3">
            <Input
              type="text"
              className="ml-4"
              onChange={handleChange}
              value={page}
            />
          </div>
        </div>
        <div className="mt-4 space-x-4 flex items-center justify-center">
          <Button
            onClick={onClose}
            className="w-28 px-4 py-2 border border-primary text-primary hover:bg-primary hover:text-white"
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} className="w-28 btn-primary">
            Go
          </Button>
        </div>
      </div>
    </Modal>
  );
}

GoToPage.propTypes = {};

GoToPage.defaultProps = {};

export default GoToPage;
