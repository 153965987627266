export default function Header() {
  return (
    <header className="border-b">
      <nav className="animate-pulse container mx-auto p-4 md:px-0 md:flex md:flex-row md:justify-between md:items-center">
        <div className="flex flex-row justify-between">
          <div className="bg-gray-200 h-8 w-56 rounded"></div>
          <span className="flex items-center cursor-pointer md:hidden">
            <div className="w-32 bg-light-blue-400 rounded"></div>
          </span>
        </div>

        <ul className="hidden sm:flex md:flex-row">
          <li className="pr-5">
            <div className="w-24 h-4 bg-gray-200 rounded"></div>
          </li>
          <li className="pr-5">
            <div className="w-24 h-4 bg-gray-200 rounded"></div>
          </li>
          <li className="pr-5">
            <div className="w-24 h-4 bg-gray-200 rounded"></div>
          </li>
          <li>
            <div className="w-24 h-4 bg-gray-200 rounded"></div>
          </li>
        </ul>
      </nav>
    </header>
  );
}
