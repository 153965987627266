export default function SidebarLoader() {
  return (
    <div className="hidden sm:block animate-pulse p-4 mt-20 border-r">
      <div>
        <div className="w-1/3 h-4 mb-3 bg-gray-200 rounded"></div>
        <div className="w-2/3 h-4 mb-3 bg-gray-200 rounded"></div>
        <div className="h-7 bg-gray-200 rounded"></div>
      </div>
      <div className="mt-8">
        <div className="w-1/3 h-4 mb-3 bg-gray-200 rounded"></div>
        <div className="w-2/3 h-4 mb-3 bg-gray-200 rounded"></div>
        <div className="h-7 bg-gray-200 rounded"></div>
      </div>
      <div className="mt-8">
        <div className="w-1/3 h-4 mb-3 bg-gray-200 rounded"></div>
        <div className="h-4 mb-3 bg-gray-200 rounded"></div>
        <div className="w-1/3 h-4 mb-3 bg-gray-200 rounded"></div>

        <div className="flex space-x-1">
          <div className="w-1/2 h-7 bg-gray-200 rounded"></div>
          <div className="w-1/2 h-7 bg-gray-200 rounded"></div>
        </div>
      </div>
      <div className="mt-8">
        <div className="flex space-x-1">
          <div className="w-1/2 h-7 bg-gray-200 rounded"></div>
          <div className="w-1/2 h-7 bg-gray-200 rounded"></div>
        </div>
      </div>
    </div>
  );
}
