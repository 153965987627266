import Page from "components/Page/Page.Loader";
import { SidebarLoader } from "components/Layout/Sidebar";

export default function AnalyticsLoader() {
  return (
    <Page>
      <div className="">
        <div className="min-h-screen-with-header animate-pulse flex items-center justify-center">
          <div className="w-1/2 mx-auto">
            <div className="h-4 my-4 bg-gray-200 rounded"></div>
            <div className="h-4 my-4 bg-gray-200 rounded"></div>
            <div className="h-4 my-4 bg-gray-200 rounded"></div>
            <div className="h-4 my-4 bg-gray-200 rounded"></div>
            <div className="w-1/3 h-4 my-4 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </Page>
  );
}
