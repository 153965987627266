import classNames from "classnames";
import PropTypes from "prop-types";

function E404({ message, isPage }) {
  return (
    <div
      className={classNames("flex items-center justify-center", {
        "w-full h-full": !isPage,
        "w-screen h-screen": isPage,
      })}
    >
      <div className="text-gray-300 italic text-lg">{message}</div>
    </div>
  );
}

E404.propTypes = {
  message: PropTypes.string.isRequired,
  isPage: PropTypes.bool,
};

E404.defaultProps = {
  message: "Requested Item does not exist",
  isPage: false,
};

export default E404;
