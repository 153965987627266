import React from "react";
import { Button, Input } from "components/Form";
import { Modal } from "components";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import GoToPageModal from "./GoToPage";
import cn from "classnames";
import "./pagination.style.scss";

class PEPagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 1,
      current_page: 1,
      disablePrev: true,
      disableNext: false,
      showGotoPage: false,
    };
  }

  goToPrev = (e) => {
    e.preventDefault();
    let newPage = --this.state.current_page;
    if (newPage > 0) {
      //console.log('prev',newPage)
      this.props.history.push(`#${newPage}`);
      this.props.onPageChange(newPage);
      this.setState({
        current_page: newPage,
      });
    } else {
      this.setState({
        current_page: 1,
      });
    }
    this.handleArrow(newPage);
  };

  handleArrow = (val) => {
    let disablePrev, disableNext;

    if (val >= this.state.total) {
      disablePrev = true;
      disableNext = false;
    } else if (val <= 1) {
      disablePrev = false;
      disableNext = true;
    } else {
      disablePrev = true;
      disableNext = true;
    }

    //console.log(disablePrev,disableNext)

    this.setState({
      disablePrev,
      disableNext,
    });
  };

  goToNext = (e, page = null) => {
    //e.preventDefault();
    let newPage = ++this.state.current_page;
    if (page) {
      newPage = page;
    }
    if (newPage <= this.state.total) {
      //console.log('next',newPage)
      this.props.history.push(`#${newPage}`);
      this.props.onPageChange(newPage);
      this.setState({
        current_page: newPage,
        showGotoPage: false,
      });
    } else {
      this.setState({
        current_page: this.state.total,
        showGotoPage: false,
      });
    }

    this.handleArrow(newPage);
  };

  changeToCurrentPage = (e, page) => {
    if (!this.isInt(page)) return;

    this.props.onPageChange(page);
    this.setState({
      current_page: page,
    });
  };

  componentWillReceiveProps(props) {
    const { data } = props;
    const { last_page = 1, page = 1, total } = data;

    this.setState({
      current_page: page,
      total: last_page,
      totalRecords: total,
    });
  }

  componentWillMount() {
    const { data } = this.props;
    const { last_page = 1, page = 1, total } = data;

    this.setState({
      current_page: page,
      total: last_page,
      totalRecords: total,
    });
  }

  renderGoToPrompt = () => {
    return (
      <GoToPageModal
        currentPage={this.state.current_page}
        visible={this.state.showGotoPage}
        onClose={() => this.setState({ showGotoPage: false })}
        onPageChange={(page) => this.props.onPageChange(page)}
      />
    );
  };

  isInt = (val) => {
    return typeof val === "number" && val % 1 === 0;
  };

  getPageNumbers = (lastPage, currentPage) => {
    let pageNumbers = [];
    if (lastPage <= 8) {
      pageNumbers = [...Array(lastPage).keys()].map((n) => n + 1);
    } else {
      if (currentPage <= 6) {
        pageNumbers = [...Array(7).keys()].map((n) => n + 1);
        pageNumbers.push("...");
        pageNumbers.push(lastPage);
      } else if (currentPage >= lastPage - 6) {
        pageNumbers.push(1);
        pageNumbers.push("...");
        pageNumbers.push(lastPage - 6);
        pageNumbers.push(lastPage - 5);
        pageNumbers.push(lastPage - 4);
        pageNumbers.push(lastPage - 3);
        pageNumbers.push(lastPage - 2);
        pageNumbers.push(lastPage - 1);
        pageNumbers.push(lastPage);
      } else {
        if (currentPage === 1) return;
        pageNumbers.push(1);
        pageNumbers.push("...");
        pageNumbers.push(currentPage - 2);
        pageNumbers.push(currentPage - 1);
        pageNumbers.push(currentPage);
        pageNumbers.push(currentPage + 1);
        pageNumbers.push(currentPage + 2);
        pageNumbers.push("...");
        pageNumbers.push(lastPage);
      }
    }

    return pageNumbers;
  };

  render() {
    if (this.state.total <= 1) return "";
    let { last_page } = this.props.data;

    let classes = {
      pageLink:
        "block py-2 px-4 border border-gray-300 hover:bg-gray-200 hover:text-primary",
      active: "text-white bg-primary border-primary",
    };

    const pageNumbers = this.getPageNumbers(last_page, this.state.current_page);
    let pages = pageNumbers.map((item, index) => {
      const href = this.isInt(item) ? item : "";
      return (
        <li className="hidden sm:inline-block" key={index}>
          <a
            className={cn(classes["pageLink"], "border-l-0", {
              [classes["active"]]: this.state.current_page === item,
            })}
            onClick={(e) => this.changeToCurrentPage(e, item)}
            href={"#" + href}
          >
            {item}
          </a>
        </li>
      );
    });

    return (
      <>
        <nav className={this.props.className} aria-label="Page navigation">
          <ul className="flex items-center rounded pl-0">
            <li>
              <a
                className={cn(classes["pageLink"])}
                onClick={this.goToPrev}
                href="#"
                aria-label="Previous"
              >
                <span aria-hidden="true">‹</span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
            {pages}
            <li>
              <a
                className={cn(classes["pageLink"], "border-l-0")}
                onClick={this.goToNext}
                href="#"
                aria-label="Next"
              >
                <span aria-hidden="true">›</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
            <Button
              className="border border-primary text-primary py-2 px-3 ml-4 hover:text-white hover:bg-primary hover:border-white"
              onClick={() => this.setState({ showGotoPage: true })}
            >
              Go to page ..
            </Button>
          </ul>
        </nav>
        {this.renderGoToPrompt()}
      </>
    );
  }
}

PEPagination.propTypes = {
  data: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

PEPagination.defaultProps = {
  onPageChange: () => null,
};

export default withRouter(PEPagination);
