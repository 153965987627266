export default function FeedbackLoader() {
  return (
    <div className="animate-pulse">
      <div className="my-2 flex flex-row space-x-2">
        <div className="w-1/2 h-9 bg-gray-200 rounded"></div>
        <div className="w-1/2 h-9 bg-gray-200 rounded"></div>
      </div>
      <div className="w-full h-9 my-2 bg-gray-200 rounded"></div>
      <div className="w-full h-24 bg-gray-200 rounded"></div>
      <div className="mt-2">
        <div className="h-3 w-1/3 bg-gray-200 rounded"></div>
      </div>
      <div className="mt-3 flex items-center justify-center">
        <div className="w-32 h-8 bg-gray-200 rounded"></div>
      </div>
    </div>
  );
}
