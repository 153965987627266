import loadable from "react-loadable";
import HeaderLoading from "./Header/Header.Loader";

//export { default as Header } from "./Header";
export { default as Footer } from "./Footer";
export { default as Sidebar } from "./Sidebar";

export const Header = loadable({
  loader: () => import("./Header"),
  loading: HeaderLoading,
  delay: 0,
  timeout: 5000,
});
