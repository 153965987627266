import React, { Component, useState, useRef, useEffect } from "react";
import classnames from "classnames";
import { EditorState, RichUtils, AtomicBlockUtils } from "draft-js";
import Editor from "draft-js-plugins-editor";
import { stateToHTML } from "draft-js-export-html";
import { mediaBlockRenderer } from "./entities/mediaBlockRenderer";
import "draft-js/dist/Draft.css";
import "./RichText.style.scss";

//https://codesandbox.io/s/n0ozyqr9z4?from-embed=&file=/src/App.js

function RText({ onChange }) {
  const editor = useRef(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleChange = (editorstate) => {
    setEditorState(editorstate);
    onChange(stateToHTML(editorstate.getCurrentContent()));
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const focus = () => editor.current.focus();

  const onAddImage = (url) => {
    const urlValue = url;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );

    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
    );
    setTimeout(() => focus(), 0);
  };

  const onUnderlineClick = () => {
    handleChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const onBoldClick = () => {
    handleChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const onItalicClick = () => {
    handleChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const handleFilePaste = (files) => {
    const allowed = ["image/png", "image/jpg"];
    let images = files.filter((file) => {
      return allowed.includes(file.type);
    });

    const blob = images[0];
    if (blob !== null) {
      let reader = new FileReader();
      reader.onload = (event) => {
        const data_url = event.target.result;
        //const data_url = 'https://raw.githubusercontent.com/facebook/draft-js/master/examples/draft-0-10-0/convertFromHTML/image.png';
        onAddImage(data_url);
      };
      reader.readAsDataURL(blob);
    }
  };

  return (
    <div className="se-rich-text">
      <div className="menuButtons d-flex justify-content-center">
        <button onClick={onUnderlineClick}>U</button>
        <button onClick={onBoldClick}>
          <b>B</b>
        </button>
        <button onClick={onItalicClick}>
          <em>I</em>
        </button>
      </div>
      <Editor
        ariaLabel="Message"
        blockRendererFn={mediaBlockRenderer}
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
        onChange={handleChange}
        placeholder={`Type your feedback, paste images`}
        //plugins={this.plugins}
        handlePastedFiles={handleFilePaste}
        ref={editor}
      />
    </div>
  );
}

export default RText;
