import {
  useDisclosureState,
  Disclosure,
  DisclosureContent,
} from "reakit/Disclosure";
import classnames from "classnames";
import "./AdvanceFilterAccordian.style.scss";

function AdvanceFilterAccordian({
  isVisible = false,
  title,
  children,
  className,
}) {
  const disclosure = useDisclosureState({ visible: isVisible });
  return (
    <>
      <DisclosureContent {...disclosure} className="content">
        {children}
      </DisclosureContent>
      <div className="text-center relative">
        <Disclosure
          {...disclosure}
          className="focus:outline-none toggle-heading text-left py-2 pl-2 text-primary2"
        >
          {title}
          <span
            className={classnames(
              "absolute top-1/2	transform -translate-y-1/2 mr-4",
              {
                "transform -rotate-90": !disclosure.visible,
              }
            )}
          >
            <img src="/icons/svg/accordian-close.svg" alt="" />
          </span>
        </Disclosure>
      </div>
    </>
  );
}

export default AdvanceFilterAccordian;
