export default function TableLoader() {
  return (
    <table className="w-full animate-pulse p-4">
      <thead>
        <tr className="border-b border-gray-300">
          <th className="p-4">
            <div className="h-8 bg-gray-200 rounded"></div>
          </th>
          <th className="p-4">
            <div className="h-8 bg-gray-200 rounded"></div>
          </th>
          <th className="p-4">
            <div className="h-8 bg-gray-200 rounded"></div>
          </th>
          <th className="p-4">
            <div className="h-8 bg-gray-200 rounded"></div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
        </tr>
        <tr>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
        </tr>
        <tr>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
        </tr>
        <tr>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
        </tr>
        <tr>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
        </tr>
        <tr>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
          <td className="p-2 px-4">
            <div className="h-6 bg-gray-200 rounded"></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
