import { useLayoutEffect } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import loadable from "react-loadable";
import { ToastContainer } from "react-toastify";
import { ProvideAuth0 } from "hooks/useAuth";
import { E404, Loading } from "../components";

import HomeLoader from "../views/Home/Home.Loader";
import AboutLoader from "../views/About/About.Loader";
import FeedbackLoader from "../views/Feedback/Feedback.Loader";
import PrivacyLoader from "../views/Privacy/Privacy.Loader";
import FAQLoader from "../views/FAQ/FAQ.Loader";
import AnalyticsLoader from "../views/Analytics/Analytics.Loader";
import SearchLoader from "../views/Search/Search.Loader";
import AuthLoader from "../views/Auth/Auth.Loader";

const FAQ = loadable({
  loader: () => import("../views/FAQ"),
  loading: FAQLoader,
  delay: 0,
  timeout: 5000,
});

const Home = loadable({
  loader: () => import("../views/Home"),
  loading: HomeLoader,
  delay: 0,
  timeout: 5000,
});

const About = loadable({
  loader: () => import("../views/About"),
  loading: AboutLoader,
  delay: 0,
  timeout: 5000,
});

const Feedback = loadable({
  loader: () => import("../views/Feedback"),
  loading: FeedbackLoader,
  delay: 0,
  timeout: 5000,
});

const Privacy = loadable({
  loader: () => import("../views/Privacy"),
  loading: PrivacyLoader,
  delay: 0,
  timeout: 5000,
});

const Analytics = loadable({
  loader: () => import("../views/Analytics"),
  loading: AnalyticsLoader,
  delay: 0,
  timeout: 5000,
});

const Search = loadable({
  loader: () => import("../views/Search"),
  loading: SearchLoader,
  delay: 0,
  timeout: 5000,
});

const Auth = loadable({
  loader: () => import("../views/Auth"),
  loading: AuthLoader,
  delay: 0,
  timeout: 5000,
});

const NotFound = () => <E404 message="Page does not exist" isPage={true} />;

export default function SERoutes() {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <ProvideAuth0>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/about" component={About} />
          <Route exact path="/feedback" component={Feedback} />
          <Route exact path="/privacy" component={Privacy} />
          {/* <Route exact path="/auth/:type" component={Auth} /> */}
          <Route exact path="/search" component={Search} />
          <Route exact path="/analytics" component={Analytics} />

          <Route path="*" component={NotFound} />
        </Switch>
      </ProvideAuth0>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
}
