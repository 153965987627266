import Page from "components/Page/Page.Loader";
import { SidebarLoader } from "components/Layout/Sidebar";
import { FooterLoader } from "components/Layout/Footer";
import { TableLoader } from "components/Widgets/Table";

export default function SearchLoader() {
  return (
    <Page>
      <div className="grid grid-cols-4 gap-4 min-h-screen-with-header">
        <SidebarLoader />
        <main className="mt-10 sm:mt-20 col-span-4 sm:col-span-3 mx-4">
          <div className="min-h-screen-with-hnf animate-pulse">
            <div className="hidden sm:block w-1/3 h-4 my-6 bg-gray-200 rounded"></div>
            <TableLoader />
          </div>
          <FooterLoader />
        </main>
      </div>
    </Page>
  );
}
