import { useState } from "react";
import classnames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "components/Shared";
import PropTypes from "prop-types";
import { Button } from "components/Form";
import "./Header.style.scss";

function Header() {
  const auth0 = useAuth0();
  const { loginWithRedirect, isAuthenticated, user, logout } = auth0;

  auth0.getAccessTokenSilently().then((token) => {
    sessionStorage.setItem("auth0Token", token);
  });
  const [active, setActive] = useState(false);
  const toggle = () => {
    setActive(!active);
  };

  const goToSignIn = () => {
    loginWithRedirect();
  };

  const doLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const path = window.location.pathname;

  return (
    <header className="border-b fixed bg-white w-full z-10 top-0 left-0">
      <nav className="container mx-auto p-4 md:px-0 md:flex md:flex-row md:justify-between md:items-center">
        <div className="flex flex-row justify-between">
          <Link to="/">
            <img src="/logo.png" className="h-12" alt="PEP Explorer Logo" />
          </Link>
          <span
            onClick={toggle}
            className="flex items-center cursor-pointer md:hidden"
          >
            <img src="/icons/menu.png" width="32" alt="Menu Button" />
          </span>
        </div>

        <ul
          className={classnames(
            "text-center md:text-left pep-menu md:flex md:flex-row",
            {
              hidden: !active,
            }
          )}
          id="mobileMenu"
        >
          <li className="pr-5 mt-2">
            <Link
              className={classnames("px-4 py-2", {
                active: path === "/search",
              })}
              to="/search"
            >
              Search
            </Link>
          </li>
          <li className="pr-5 mt-2">
            <Link
              className={classnames("px-4 py-2", {
                active: path === "/analytics",
              })}
              to="/analytics"
            >
              Analytics
            </Link>
          </li>
          <li className="pr-5 mt-2">
            <Link
              className={classnames("px-4 py-2", {
                active: path === "/about",
              })}
              to="/about"
            >
              About
            </Link>
          </li>
          <li className="pr-5 mt-2">
            <Link
              className={classnames("px-4 py-2", {
                active: path === "/feedback",
              })}
              to="/feedback"
            >
              Feedback
            </Link>
          </li>
          <li>
            {!isAuthenticated && (
              <Button onClick={goToSignIn} className="btn-primary">
                Sign In
              </Button>
            )}
            {isAuthenticated && (
              <Button onClick={doLogout} className="btn-primary">
                Sign Out
              </Button>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
