import React from "react";
import PropTypes from "prop-types";
import API from "services/APIs";
import AsyncSelect from "react-select/async";
import { Country } from "components/Form";
import "./Organization.style.scss";

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: null,
      organization: null,
    };
  }

  componentDidMount() {
    const { countries, organization } = this.props;
    this.setState({ countries, organization });
  }

  handleChange = (field, value) => {
    const state = { ...this.state };
    this.setState({
      [field]: field === "organization" ? value.value : value,
    });
    state[field] = field === "organization" ? value.value : value;
    this.props.onChange(state);
  };

  search = async (keyword) => {
    try {
      const { countries } = this.state;
      let params = {
        keyword,
        country: countries,
      };
      let results = await API.getOrgs(params);
      let options = results.map((item) => {
        return {
          value: item,
          label: item,
        };
      });

      return new Promise((resolve, reject) => {
        resolve(options);
      });
    } catch (error) {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  };
  render() {
    const { countries = null, organization = null } = this.state;

    return (
      <>
        <Country
          onChange={(val) => this.handleChange("countries", val)}
          placeholder={`Country e.g. Ukraine, Russia`}
          value={countries}
          isMulti={false}
        />
        <AsyncSelect
          className="mt-2"
          onChange={(val) => this.handleChange("organization", val)}
          value={
            organization ? { label: organization, value: organization } : null
          }
          placeholder="Enter org. name"
          isDisabled={!countries}
          loadOptions={this.search}
        />
      </>
    );
  }
}

Organization.propTypes = {
  onChange: PropTypes.func,
};

Organization.defaultProps = {
  onChange: () => {},
};

export default Organization;
