import { memo } from "react";
import PropTypes from "prop-types";
import { Header } from "../Layout";
import "./Page.style.scss";
const MemoizedHeader = memo((props) => <Header {...props} />);
function Page({ children }) {
  return (
    <>
      <MemoizedHeader />
      {children}
    </>
  );
}

Page.propTypes = {};

Page.defaultProps = {};

export default Page;
