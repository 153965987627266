import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import advSearchFields from "data/adv-search-field.json";
import { Input, Select2, Country } from "components/Form";
import Organization from "../Organization";
import "./AdvanceFilter.style.scss";

function AdvanceFilter({ onUpdate, value = {}, doReset = false }) {
  const [adv, setAdv] = useState({});
  const [fields, setFields] = useState([]);

  useEffect(() => {
    setFields(Object.keys(value));
    setAdv(value);
  }, []);

  useEffect(() => {
    if (doReset) {
      setFields([]);
      setAdv({});
    }
  }, [doReset]);

  const handleChange = (name, value) => {
    const newAdv = { ...adv };
    newAdv[name] = value;
    setAdv(newAdv);
    onUpdate(newAdv);
  };

  const handleDelete = (name) => {
    const newAdv = { ...adv };
    delete newAdv[name];
    setAdv(newAdv);
    let selected = [...fields];
    const newFields = selected.filter((item) => {
      return item !== name;
    });
    setFields(newFields);
  };

  const handleOrgChange = (org) => {
    const newAdv = { ...adv, ...org };
    setAdv(newAdv);
    onUpdate(newAdv);
  };

  let options = Object.values(advSearchFields).map(({ name, label }) => {
    if (
      !fields.includes(name) &&
      !(fields.includes("organization") && name === "countries")
    ) {
      return {
        value: name,
        label: label,
      };
    }
  });
  options = options.filter((e) => e);
  return (
    <div className="filter-group mt-3">
      {options.length > 0 && (
        <label className="filter-group-title w-100 mb-2">
          Additional Filters
          <p className="my-3 text-xs">
            Add additional filter to narrow results
          </p>
          <Select2
            tabIndex="-1"
            onChange={({ value }) => {
              let fieldsClone = [...fields];
              if (fields.includes("countries") && value === "organization") {
                fieldsClone = fieldsClone.filter((item) => {
                  return item !== "countries";
                });
              }
              setFields([...fieldsClone, value]);
            }}
            value={null}
            options={options}
            placeholder="Select Field"
          />
        </label>
      )}
      <div className="mt-2">
        {fields.map((item) => {
          let { name, label, placeholder, type = null } = advSearchFields[item];
          return (
            <div key={name} className="mt-1">
              <label
                className="flex items-center my-1 text-xs"
                htmlFor={`#sf-${item}`}
              >
                {label}{" "}
                <span className="ml-auto" onClick={() => handleDelete(item)}>
                  <img width="12" src="/icons/svg/cross.svg" alt="" />
                </span>
              </label>
              {name === "organization" && (
                <Organization
                  countries={adv["countries"] || null}
                  organization={adv["organization"] || null}
                  onChange={handleOrgChange}
                />
              )}
              {type === "country" && (
                <Country
                  onChange={(val) => handleChange(item, val)}
                  placeholder={placeholder}
                  value={adv[item] || null}
                  isMulti={false}
                />
              )}
              {name !== "organization" && !type && (
                <Input
                  id={`sf-${item}`}
                  value={adv[item] || ""}
                  onChange={(e) => handleChange(item, e.target.value)}
                  placeholder={placeholder}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

AdvanceFilter.propTypes = {};

AdvanceFilter.defaultProps = {};

export default AdvanceFilter;
