import { useState } from "react";
import cn from "classnames";
import "./LangSwitch.style.scss";

const LangSwitch = ({ className = "", value = true, onChange = null }) => {
  let [checked, setChecked] = useState(value);

  const handleChange = (e) => {
    if (onChange) {
      setChecked(e.target.checked);
      onChange(e.target.checked);
    }
  };

  return (
    <div className={cn("switch-button", className, { "sb-white": checked })}>
      <input
        onChange={handleChange}
        className="switch-button-checkbox"
        type="checkbox"
        defaultChecked={value}
      ></input>
      <label className="switch-button-label">
        <span className="switch-button-label-span">English</span>
      </label>
    </div>
  );
};

export default LangSwitch;
