import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const formatDate = (date) => {
  let d = date,
    year = d.getFullYear();
  return year;
};

function PEPYearRange({
  title = null,
  description = null,
  className,
  value = null,
  onChange,
  doReset,
}) {
  const [yearRange, setYearRange] = useState({
    startYear: null,
    endYear: null,
  });

  useEffect(() => {
    if (value) {
      let newValue = {};
      for (const key in value) {
        newValue[key] = value[key] ? new Date(value[key]) : null;
      }
      console.log(newValue);
      setYearRange(newValue);
    }
  }, []);

  useEffect(() => {
    if (doReset) {
      setYearRange({
        startYear: null,
        endYear: null,
      });
    }
  }, [doReset]);

  const { startYear, endYear } = yearRange;

  const handleChange = (field, value) => {
    let newYearRange = { ...yearRange };
    newYearRange[field] = value;
    setYearRange(newYearRange);

    //
    const result = {
      startYear:
        newYearRange.startYear && typeof newYearRange.startYear === "object"
          ? formatDate(newYearRange.startYear)
          : newYearRange.startYear,
      endYear:
        newYearRange.endYear && typeof newYearRange.endYear === "object"
          ? formatDate(newYearRange.endYear)
          : newYearRange.endYear,
    };
    onChange(result);
  };

  return (
    <div className={classnames("pep-year-picker", className)}>
      {title && <h4>Year range</h4>}
      {description && <p className="my-3 text-xs">{description}</p>}
      <div className="grid grid-cols-2 gap-1">
        <DatePicker
          ariaLabelledBy="start_year"
          placeholderText="Start Year"
          className="border border-gray-200 text-gray-900 py-1 px-4 focus:border-primary2 hover:border-primary2 outline-none w-full"
          minDate={new Date("1990")}
          dateFormat="yyyy"
          selected={startYear}
          showYearPicker={true}
          onChange={(date) => handleChange("startYear", date)}
        />
        <DatePicker
          ariaLabelledBy="end_year"
          placeholderText="End Year"
          className="border border-gray-200 text-gray-900 py-1 px-4 focus:border-primary2 hover:border-primary2 outline-none w-full"
          minDate={startYear ? new Date(startYear) : null}
          dateFormat="yyyy"
          selected={endYear}
          showYearPicker={true}
          onChange={(date) => handleChange("endYear", date)}
        />
      </div>
    </div>
  );
}

PEPYearRange.propTypes = {
  onChange: PropTypes.func,
};

PEPYearRange.defaultProps = {
  onChange: () => {},
};

export default PEPYearRange;
