import React from "react";
import PropTypes from "prop-types";
import "./Loading.style.scss";

function Loading({ visible = true }) {
  if (!visible) return false;
  return (
    <div className="loading-container">
      <div className="d-flex m-1">
        <div className="loader">Loading...</div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  visible: PropTypes.bool.isRequired,
};

Loading.defaultProps = {
  visible: true,
};

export default Loading;
