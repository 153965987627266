import Page from "components/Page/Page.Loader";
import Feedback from "components/Widgets/Feedback/Feedback.Loader";
export default function PEPFeedBack() {
  return (
    <Page>
      <main className="animate-pulse w-4/5 lg:w-3/5 mx-auto my-16">
        <div className="bg-gray-200 h-6 w-64 rounded"></div>
        <div className="my-4 space-y-2">
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
        </div>
        <br />
        <div className="my-4 space-y-2">
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
        </div>
        <br />
        <div className="my-4 space-y-2">
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
        </div>
        <br />
        <div className="my-4 space-y-2">
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 w-2/3 bg-gray-200 rounded"></div>
        </div>
        <br />
        <Feedback />
      </main>
    </Page>
  );
}
