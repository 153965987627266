import { Header } from "components";
function ErrorPage() {
  return (
    <>
      <Header />
      <div className="min-h-screen-with-header mt-20 flex items-center justify-center">
        <div className="text-center text-gray-500">
          <svg
            className="fill-current w-24 h-24 mx-auto mb-4"
            height="512pt"
            viewBox="-64 0 512 512"
            width="512pt"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill-rule="evenodd">
              <path d="m10 401.996094c5.523438 0 10-4.476563 10-10v-371.996094h344.445312v20.003906c0 5.519532 4.476563 10 10 10 5.523438 0 10-4.480468 10-10v-30.003906c0-5.523438-4.476562-10-10-10h-364.445312c-5.523438 0-10 4.476562-10 10v381.996094c0 5.523437 4.476562 10 10 10zm0 0" />
              <path d="m374.445312 110.003906c-5.523437 0-10 4.476563-10 10v267.976563h-94.019531c-5.523437 0-10 4.476562-10 10v94.019531h-240.425781v-20.003906c0-5.519532-4.476562-10-10-10s-10 4.480468-10 10v30.003906c0 5.523438 4.476562 10 10 10h260.425781c.050781 0 .101563-.007812.15625-.007812.273438-.003907.546875-.015626.820313-.039063.167968-.015625.332031-.050781.496094-.074219.15625-.023437.316406-.039062.46875-.070312.167968-.03125.332031-.082032.5-.121094.148437-.039062.300781-.070312.449218-.113281.15625-.046875.304688-.109375.457032-.164063.15625-.054687.3125-.101562.464843-.164062.136719-.058594.269531-.128906.402344-.191406.164063-.074219.328125-.144532.484375-.226563.125-.066406.246094-.144531.367188-.21875.160156-.09375.320312-.179687.472656-.285156.144531-.09375.277344-.203125.414062-.304688.128906-.09375.257813-.179687.378906-.28125.257813-.207031.503907-.429687.738282-.667969l104.019531-104.019531c.316406-.316406.601563-.65625.867187-1.007812.070313-.085938.136719-.175781.199219-.265625.25-.355469.488281-.714844.691407-1.097656.011718-.023438.019531-.046876.03125-.070313.1875-.363281.351562-.738281.492187-1.121094.039063-.101562.078125-.207031.113281-.3125.136719-.40625.257813-.816406.34375-1.238281 0-.007812 0-.015625.003906-.027344.078126-.410156.125-.832031.152344-1.253906.011719-.113281.015625-.226562.019532-.339844.003906-.113281.015624-.222656.015624-.335937v-277.976563c0-5.523437-4.476562-10-10-10zm-94.019531 367.855469v-69.878906h69.875zm0 0" />
              <path d="m94.222656 308.785156c25.007813-20.148437 60.726563-31.707031 98-31.707031 37.273438 0 72.992188 11.558594 98 31.707031 1.847656 1.492188 4.066406 2.214844 6.269532 2.214844 2.917968 0 5.816406-1.273438 7.792968-3.726562 3.464844-4.300782 2.785156-10.597657-1.515625-14.0625-28.496093-22.960938-68.792969-36.132813-110.546875-36.132813s-82.050781 13.171875-110.546875 36.132813c-4.304687 3.464843-4.980469 9.761718-1.515625 14.0625 3.464844 4.296874 9.761719 4.980468 14.0625 1.511718zm0 0" />
              <path d="m303.566406 107.925781c-3.902344-3.90625-10.234375-3.90625-14.140625 0l-22.597656 22.597657-22.601563-22.597657c-3.902343-3.90625-10.234374-3.90625-14.140624 0-3.90625 3.902344-3.90625 10.234375 0 14.140625l22.597656 22.597656-22.597656 22.601563c-3.90625 3.902344-3.90625 10.234375 0 14.140625 3.902343 3.90625 10.234374 3.90625 14.140624 0l22.601563-22.597656 22.597656 22.597656c1.953125 1.953125 4.511719 2.929688 7.070313 2.929688s5.117187-.976563 7.070312-2.929688c3.90625-3.90625 3.90625-10.238281 0-14.140625l-22.597656-22.601563 22.597656-22.597656c3.90625-3.90625 3.90625-10.238281 0-14.140625zm0 0" />
              <path d="m80.878906 181.40625c3.902344 3.902344 10.234375 3.902344 14.140625-.003906l22.597657-22.597656 22.597656 22.597656c1.953125 1.953125 4.511718 2.929687 7.070312 2.929687s5.121094-.976562 7.070313-2.929687c3.90625-3.902344 3.90625-10.234375.003906-14.140625l-22.597656-22.597657 22.597656-22.601562c3.902344-3.902344 3.902344-10.234375-.003906-14.140625-3.902344-3.902344-10.234375-3.90625-14.140625 0l-22.597656 22.597656-22.597657-22.597656c-3.90625-3.90625-10.238281-3.90625-14.140625 0-3.90625 3.90625-3.90625 10.238281 0 14.140625l22.597656 22.601562-22.597656 22.597657c-3.90625 3.90625-3.90625 10.238281 0 14.144531zm0 0" />
              <path d="m10 441.996094c5.507812 0 10-4.488282 10-10 0-5.507813-4.492188-10-10-10s-10 4.492187-10 10c0 5.511718 4.492188 10 10 10zm0 0" />
              <path d="m374.445312 70.003906c-5.507812 0-10 4.488282-10 10 0 5.507813 4.492188 10 10 10 5.507813 0 10-4.492187 10-10 0-5.511718-4.492187-10-10-10zm0 0" />
            </g>
          </svg>
          <h1 className="text-2xl">Sorry It's not you. It's Us</h1>
          <p className="text-sm">
            We are experiencing an internal server problem <br /> Please try
            again later
          </p>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
