import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { isEnglish } from "helpers/Utils";
import Loader from "./Table.Loader";
import "./Table.style.scss";

class PEPTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: {},
    };
  }

  handleSortClick = async (e, field) => {
    e.preventDefault();
    const { sortParams: sort, lang } = this.props;
    const arr = Object.values(this.state.sort);
    let val = arr.length > 0 ? arr[0] : false;
    if (!val) {
      val = "asc";
    } else {
      val = this.state.sort[field] === "asc" ? "desc" : "asc";
    }

    const fieldMaps = {
      country: "country",
      name: `person_name${lang}`,
      role: `organization_role${lang}`,
      organization: `organization_name${lang}`,
    };

    await this.setState({
      sort: { ...this.state.sort, [field]: val },
    });

    await this.props.onSort(
      {
        [fieldMaps[field]]: val,
      },
      { ...this.state.sort, [field]: val }
    );
  };

  render() {
    const {
      className,
      tableData = {},
      onRowClick = null,
      isLoading,
    } = this.props;

    if (isLoading) {
      return <Loader />;
    }
    const { headers = [], data = [] } = tableData;
    return (
      <>
        {data.length === 0 && (
          <div className="text-primary2 border border-gray-300 p-5">
            <h2 className="text-uppercase text-2xl">SEARCH INFORMATION</h2>
            <h4 className="mt-2 text-sm">TIPS</h4>
            <ul className="list-disc text-sm pl-6">
              <li>
                If your searches are returning 0 results, try removing some
                words in the text boxes or remove some filters entirely.
              </li>
              <li>
                You may be using advanced search operators such as quotation
                marks; try removing these.
              </li>
              <li>
                There is no boolean search (e.g. "Russia OR Ukraine"). We are
                working on adding it.
              </li>
            </ul>
          </div>
        )}

        {data.length > 0 && (
          <table className={classnames("w-full", className)}>
            <thead>
              <tr className="border-b border-gray-300">
                {headers.map((header) => {
                  const { title, description, field, sort } = header;
                  return (
                    <th
                      className="text-left p-2"
                      scope="col"
                      onClick={(e) => this.handleSortClick(e, field)}
                      key={field}
                    >
                      <span className="w-full flex items-center">
                        {title}{" "}
                        {
                          <span
                            className={classnames(
                              "ml-auto sort",
                              {
                                asc: sort === "asc",
                                desc: sort === "desc",
                              },
                              "cursor-pointer"
                            )}
                          >
                            <img
                              className={
                                "sort" in header && sort === "desc"
                                  ? "mb-0.5 opacity-0"
                                  : "mb-0.5 opacity-1"
                              }
                              src="/icons/svg/sort-up.svg"
                              alt=""
                            />
                            <img
                              className={
                                "sort" in header && sort === "asc"
                                  ? "opacity-0"
                                  : "opacity-1"
                              }
                              src="/icons/svg/sort-down.svg"
                              alt=""
                            />
                          </span>
                        }
                      </span>
                      <small className="text-xs font-normal hidden sm:block">
                        {description}
                      </small>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className={classnames("cursor-pointer", {
                      "bg-gray-300": (i + 1) % 2 == 0,
                    })}
                    onClick={() => onRowClick(item)}
                  >
                    {headers.map((col, j) => {
                      if (j === 0) {
                        return (
                          <td
                            style={
                              {
                                //fontFamily: isEnglish(item[col.field])
                                // \? "inherit"
                                // : "NanumGothic",
                              }
                            }
                            className="p-3 text-sm"
                            scope="row"
                            key={j}
                          >
                            {item[col.field]}
                          </td>
                        );
                      }
                      return (
                        <td
                          className="p-3 text-sm"
                          scope={j === 0 ? "row" : ""}
                          key={j}
                        >
                          {item[col.field]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </>
    );
  }
}

PEPTable.propTypes = {
  className: PropTypes.string,
  onRowClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

PEPTable.defaultProps = {
  className: "",
  onRowClick: () => null,
  isLoading: false,
};

export default PEPTable;
