import { Input } from "reakit/Input";
import classnames from "classnames";
import "./Input.style.scss";

function PEPInput({ className, ...restProps }) {
  return (
    <Input
      className={classnames(
        "border border-gray-200 text-gray-900 py-1 px-4 focus:border-primary2 hover:border-primary2 outline-none w-full",
        className
      )}
      {...restProps}
    />
  );
}
export default PEPInput;
