import { Button } from "reakit/Button";
import {
  usePopoverState,
  Popover,
  PopoverArrow,
  PopoverDisclosure,
} from "reakit/Popover";
import "./Popover.style.scss";

const C4Popover = ({ title, content }) => {
  const popover = usePopoverState({ animated: 250, placement: "auto" });
  return (
    <>
      <PopoverDisclosure {...popover}>{title}</PopoverDisclosure>
      <Popover
        {...popover}
        aria-label="Welcome"
        style={{ border: 0, background: "none", padding: 0 }}
      >
        <div className="popover-cta">
          <PopoverArrow {...popover} />
          {content}
          <Button onClick={popover.hide}>Close</Button>
        </div>
      </Popover>
    </>
  );
};

export default C4Popover;
