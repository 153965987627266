import { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Button } from "components/Form";
import AdvanceFilter from "./AdvanceFilter";
import YearRange from "./YearRange";
import AdvanceFilterAccordian from "./AdvanceFilterAccordian";
import _ from "lodash";
import { useWindowResize } from "hooks";
import { getUrlParams } from "helpers/Utils";
import advSearchFields from "data/adv-search-field.json";
import "./Sidebar.style.scss";

function Sidebar({ params = {}, onReset = null, onUpdate = null }) {
  const [searchParams, setSearchParams] = useState({
    search: {},
    filter: {},
    sort: {},
  });
  const [doReset, setDoReset] = useState(false);
  let { width } = useWindowResize();
  let { search = {}, filter = {}, sort = {} } = searchParams;

  useEffect(() => {
    const { q = "" } = getUrlParams();
    let newParams = { ...params };
    if (q) {
      newParams.search.query = q;
    }
    setSearchParams(newParams);
    onUpdate(newParams, true);
  }, []);

  const handleChange = (field, value) => {
    const newParams = { ...searchParams };
    if (field === "search") {
      newParams.search.query = value;
    } else if (field === "advance_search") {
      newParams.search.adv = { ...newParams.search.adv, ...value };
    } else if (field === "yearRange") {
      newParams.filter.yearRange = value;
    }
    setSearchParams(newParams);
  };

  const handleChangeSearch = (e) => {
    handleChange("search", e.target.value);
  };

  const handleAdvSearch = (value) => {
    handleChange("advance_search", value);
  };

  const handleYearRangeChange = (value) => {
    handleChange("yearRange", value);
  };

  const handleResetFilter = (e) => {
    e.preventDefault();
    onReset();
    setDoReset(Math.random());
    setSearchParams({ search: { query: "" }, filter: {}, sort: {} });
    //onUpdate({}, true);
  };

  const handleSubmit = () => {
    onUpdate(searchParams);
  };

  return (
    <aside className="mt-20 col-span-4 md:col-span-1 relative md:h-screen-with-header">
      {/* Desktop */}
      {width > 768 && (
        <nav className="md:fixed h-full p-5 md:w-1/4 bg-gray-100">
          <div className="">
            <h4 className="text-lg">Search</h4>
            <p className="my-3 text-xs">Enter a name to search</p>
            <Input
              onChange={handleChangeSearch}
              type="text"
              placeholder="Search"
              value={search.query}
            />
          </div>
          <AdvanceFilter
            doReset={doReset}
            onUpdate={handleAdvSearch}
            className="mt-4"
            value={search.adv}
          />
          <YearRange
            doReset={doReset}
            value={filter.yearRange || null}
            onChange={handleYearRangeChange}
            title="Year range"
            description="Search for individuals added to PEP list within selected year range"
            className="mt-4"
          />
          <div className="mt-8 flex items-center">
            <a
              href="#"
              onClick={handleResetFilter}
              className="hover:text-primary2 hover:underline"
            >
              Reset all filters
            </a>
            <Button onClick={handleSubmit} className="ml-auto btn-primary">
              Search
            </Button>
          </div>
        </nav>
      )}
      {/* mobile */}
      {width <= 768 && (
        <nav className="md:fixed h-full p-5 md:w-1/4 bg-gray-100">
          <div className="">
            <h4 className="text-lg">Search</h4>
            <p className="my-3 text-xs">Enter a name to search</p>
            <Input
              onChange={handleChangeSearch}
              type="text"
              placeholder="Search"
              value={search.query}
            />
          </div>
          <AdvanceFilterAccordian title="Advance Filter">
            <AdvanceFilter
              doReset={doReset}
              onUpdate={handleAdvSearch}
              className="mt-4"
              value={search.adv}
            />
            <YearRange
              doReset={doReset}
              value={filter.yearRange || null}
              onChange={handleYearRangeChange}
              title="Year range"
              description="Search for individuals added to PEP list within selected year range"
              className="mt-4"
            />
            <div className="mt-8 flex items-center">
              <a
                href="#"
                onClick={handleResetFilter}
                className="hover:text-primary2 hover:underline"
              >
                Reset all filters
              </a>
              <Button onClick={handleSubmit} className="ml-auto btn-primary">
                Search
              </Button>
            </div>
          </AdvanceFilterAccordian>
        </nav>
      )}
    </aside>
  );
}

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
