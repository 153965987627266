import { Link } from "components/Shared";
import "./Footer.style.scss";

function Footer() {
  return (
    <footer className="border-t p-4">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="w-full divide-y-2 divide-red-600 md:w-1/2 lg:w-1/4 md:pr-4 lg:pr-0">
          <img className="h-12 pb-3" src="/c4ads-black.png" alt="C4ADS Logo" />
          <p>
            C4ADS is a nonprofit organization dedicated to providing data-driven
            analysis and evidence-based reporting on global conflict and
            transnational security issues.
          </p>
        </div>
        <nav className="w-full pt-4 md:pt-0 md:w-1/2 lg:w-2/4">
          <ul className="grid text-left md:text-right grid-cols-2 sm:grid-cols-3 gap-4">
            <li>
              <Link to="/feedback">Contact Us</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="privacy">Privacy Policy</Link>
            </li>
            <li>
              <a href="mailto:info@c4ads.com">info@c4ads.com</a>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
