import Header from "../Layout/Header/Header.Loader";

export default function PageLoader({ children }) {
  return (
    <>
      <Header />
      {children}
    </>
  );
}
