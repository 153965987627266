export function truncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...[click to see in details]";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export function getUrlParams() {
  var result = {};
  var params = (window.location.search.split("?")[1] || "").split("&");
  for (var param in params) {
    if (params.hasOwnProperty(param)) {
      var paramParts = params[param].split("=");
      result[paramParts[0]] = decodeURIComponent(paramParts[1] || "");
    }
  }
  return result;
}

export function capitalize(str) {
  if (!str) return "";
  return str
    .toLowerCase()
    .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
}

export function uppercase(str) {
  if (!str) return "";
  return str.toUpperCase();
}

function _isEnglish(str) {
  if (!str) return true;
  const regEx = /^[a-zA-Z0-9\t\n ./<>?;:"'`!@#$%^&*()–,\[\]{}_+=|\\-]*$/;
  return regEx.test(str);
}

export function isEnglish(str) {
  return _isEnglish(str);
}

export function unicodeFont(val) {
  return { "font-sans-unicode": !_isEnglish(val) };
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
