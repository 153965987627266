import { Button } from "reakit/Button";
import classnames from "classnames";
import "./Button.style.scss";

function PEPButton({
  children,
  className,
  disabled,
  isLoading = false,
  loadingText = "Submitting..",
  ...restProps
}) {
  return (
    <Button
      disabled={disabled || isLoading}
      className={classnames("", className)}
      {...restProps}
    >
      {isLoading ? loadingText : children}
    </Button>
  );
}

export default PEPButton;
