import { useState } from "react";
import { Checkbox } from "reakit/Checkbox";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Checkbox.style.scss";

const PEPCheckbox = ({
  id,
  className,
  label,
  labelPopover,
  onChange,
  value,
  required,
  uncheckedPrimary,
}) => {
  const handleChange = (e) => (!onChange ? null : onChange(e.target.checked));
  return (
    <div className={classNames("p-6", className)}>
      <Checkbox
        id={id}
        onChange={handleChange}
        //defaultChecked={value}
        checked={value}
        required={required}
      />
      <label
        className="block relative items-center text-base text-gray-400 capitalize"
        //className={!uncheckedPrimary ? "border-default" : ""}
        style={{
          fontSize: label && label.length >= 30 && "13px",
          lineHeight: label && label.length >= 30 && "15px",
        }}
        htmlFor={id}
      >
        {label}{" "}
        {labelPopover && (
          <span id={`pop-${id}`} className="cursor-pointer">
            <i className="fas fa-info-circle"></i>
          </span>
        )}
      </label>
    </div>
  );
};

PEPCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  required: PropTypes.bool,
  uncheckedPrimary: PropTypes.bool,
};

PEPCheckbox.defaultProps = {
  value: false,
  className: "",
  label: "",
  labelPop: false,
  uncheckedPrimary: true,
};

export default PEPCheckbox;
