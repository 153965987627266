import { useState, useEffect } from "react";
import { useDialogState, Dialog, DialogBackdrop } from "reakit/Dialog";
import cn from "classnames";
import "./Modal.style.scss";

export default function Modal({
  visible = false,
  dialogClass = "",
  className = "",
  children,
}) {
  let dialog = useDialogState();
  dialog.visible = visible;
  return (
    <DialogBackdrop className="focus:ring-0 modal-backdrop" {...dialog}>
      <Dialog
        className={cn(
          "bg-white",
          { dialog: !dialogClass },
          dialogClass,
          className
        )}
        tabIndex={0}
        {...dialog}
        aria-label="Popup"
      >
        {children}
      </Dialog>
    </DialogBackdrop>
  );
}
